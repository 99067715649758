<script lang="ts" setup>
	import useSizes from "~/composables/useSizes";
	import { Sizes } from "~/utils/enums";

	const props = withDefaults(
		defineProps<{
			size?: Sizes;
		}>(),
		{
			size: "small",
		}
	);

	const { size } = toRefs(props);

	const { isSizeSmall, isSizeLarge } = useSizes(size);
</script>

<template>
	<svg class="icon-companies" v-if="isSizeLarge" width="69" height="69" viewBox="0 0 69 69" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M9 1.5H60C64.1421 1.5 67.5 4.85786 67.5 9V67.5H34.5V51C34.5 48.5147 32.4853 46.5 30 46.5H24C21.5147 46.5 19.5 48.5147 19.5 51V67.5H1.5V9C1.5 4.85787 4.85786 1.5 9 1.5Z"
			stroke="black"
			stroke-width="3"
		/>
		<rect x="13" y="12" width="9" height="9" fill="black" />
		<rect x="13" y="27" width="9" height="9" fill="black" />
		<rect x="30" y="12" width="9" height="9" fill="black" />
		<rect x="30" y="27" width="9" height="9" fill="black" />
		<rect x="47" y="12" width="9" height="9" fill="black" />
		<rect x="47" y="27" width="9" height="9" fill="black" />
		<rect x="47" y="42" width="9" height="9" fill="black" />
	</svg>

	<svg class="icon-companies" v-if="isSizeSmall" width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M3 1H20C21.1046 1 22 1.89543 22 3V22H12V18C12 16.3431 10.6569 15 9 15C7.34315 15 6 16.3431 6 18V22H1V3C1 1.89543 1.89543 1 3 1Z" stroke="black" stroke-width="2" />
		<rect x="5" y="4" width="3" height="3" fill="black" />
		<rect x="5" y="9" width="3" height="3" fill="black" />
		<rect x="10" y="4" width="3" height="3" fill="black" />
		<rect x="10" y="9" width="3" height="3" fill="black" />
		<rect x="15" y="4" width="3" height="3" fill="black" />
		<rect x="15" y="9" width="3" height="3" fill="black" />
		<rect x="15" y="14" width="3" height="3" fill="black" />
	</svg>
</template>

<style scoped lang="scss">
	.icon-companies {
		rect,
		path {
			transition: all 0.2s ease-out;
		}
	}
</style>
